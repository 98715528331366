import React, { useEffect, useState } from "react"
import { getKPIByCustomer } from "../../../../../../middleware/middleware-layer";
import { useMsal } from "@azure/msal-react";
import { HireStatisticsCharts } from "../../../../../hire-statistics";
import { NewContractButton } from "../../../../../new-contract-button";
import { useCustomerContext } from "../../store";

export default function HireStatistics(props: any) {
    const { customer } = useCustomerContext()
    const { instance, accounts } = useMsal()
    const [isLoading, setIsLoading] = useState(true);
    const [statisticsUnavailable, setStatisticsUnavailable] = useState(false);
    const [chartData, setChartData] = useState([]);

    const getCharts = async () => {
        setIsLoading(true);
  
        // Last 12 months including the current month
        const today = new Date();
        const endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        const startDate = new Date(today.getFullYear(), today.getMonth(), 1);
        startDate.setMonth(today.getMonth() - 11);

        const getOffsetISOString = (date: Date, time: string) => {
            let offset = date.getTimezoneOffset()
            if (offset !== 0) {
                offset = offset * -1
            }
            return new Date(date.getTime() + (offset * 60000)).toISOString().substring(0, 11) + `${time}.000Z`
        }
  
        getKPIByCustomer(
            accounts,
            instance,
            props.customerId,
            getOffsetISOString(startDate, '00:00:00'),
            getOffsetISOString(endDate, '23:59:59'),
        ).then((results) => {
            if (results.data.internalGetKPIByCustomer) {
                setChartData(results.data.internalGetKPIByCustomer.charts);
              } else {
                setStatisticsUnavailable(true);
              }
        }).catch(() => {
            setStatisticsUnavailable(true);
        }).finally(() => {
            setIsLoading(false);
        })        
    };

    useEffect(() => {
        getCharts()
    }, [props.customerId])

    return props.customerId ? (
        <>
        <div className="flex flex-row justify-end mb-6">
            <h2 className="flex-1 inline-flex mt-8 mb-6 mr-6 text-3xl font-bold uppercase">
                {customer?.customerName && customer?.customerName?.toUpperCase()}
            </h2>
            <NewContractButton 
                accounts={accounts}
                className="mt-8"
                customer={customer}
                instance={instance}
            />
            </div>
            <HireStatisticsCharts
                charts={chartData}
                isLoading={isLoading}
                statisticsUnavailable={statisticsUnavailable}
            />
        </>
    ) : null
}